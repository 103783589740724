import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Conversation from "./Conversation";
import { checkOnlineStatus, getConversationId } from "../utils/chat";
import { open_contact, getConversations } from "../redux/features/chat/chatSlice";
import axios from "axios";
import ChatLoading from "./ChatLoading";

const Conversations = ({socket,onlineUsers,typing,isHidden,setIsHidden,unreadCount,setUnreadCount,defaultUsers}) => {

	const { conversations, activeConversation } = useSelector((state) => state.chat);
	const { user, users } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	// const [isFetchingMore, setIsFetchingMore] = useState(false);
  // const [page, setPage] = useState(1);
  // const [displayedConversations, setDisplayedConversations] = useState(10);
	// console.log(users);

  // const fetchMoreConversations = () => {
  //   if (isFetchingMore || displayedConversations >= conversations.length) return;

  //   setIsFetchingMore(true);
  //   // Simulate delay to show loader
  //   setTimeout(() => {
  //     setDisplayedConversations((prev) => prev + 10); // Show next 10 conversations
  //     setIsFetchingMore(false);
  //   }, 1000); // Simulating an API delay
  // };

  // const handleScroll = (e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target;
  //   if (scrollHeight - scrollTop <= clientHeight + 50 && !isFetchingMore) {
  //     fetchMoreConversations();
  //   }
  // };
	

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	// useEffect(() => {
  //   if (user) {
  //     setLoading(true);
  //     dispatch(getConversations({ page: 1 }))
  //       .then(() => setPage(2))
  //       .finally(() => setLoading(false));
  //   }
  // }, [dispatch, user]);

useEffect(() => {
	    if (user) {
	      setLoading(true);
	      dispatch(getConversations()).then(() => setLoading(false));
	    }
	}, [dispatch, user]);

	if (loading) {
	    return <div><ChatLoading/></div>;
	}

	return (
		<>
			<div className="chat scrollBar" style={user?.role === 'student' ? {height : windowHeight.winHeight - 180} : {height : windowHeight.winHeight - 150}}>
				<ul>
					{
						Array.isArray(conversations) && conversations.filter((c)=> c?.latestMessage || c?._id === activeConversation?._id || c.isGroup === true).map((chat)=>{
							let check = checkOnlineStatus(onlineUsers, user, chat.users); 
							return(
							<Conversation chat={chat} key={chat._id} online={!chat.isGroup && check ? true : false} typing={typing} isHidden={isHidden} setIsHidden={setIsHidden} unreadCount={unreadCount} setUnreadCount={setUnreadCount} defaultUsers={defaultUsers}/>
							)}	
						)
					}
				</ul>
				{/*{isFetchingMore && <ChatLoading />}*/}
			</div>
		</>
	)
}

export default Conversations